import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBannerEdit() {

  const fetchBanner = async (id) => {
    try {
      const response = await store.dispatch('app-banner/fetchBanner', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const deleteBanner = async (id) => {
    try {
      const response = await store.dispatch('app-banner/deleteBanner', id);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    fetchBanner,
    deleteBanner,
  }
}
